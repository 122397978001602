interface PressItem {
    title: string,
    date: number,
    provider: string,
    source: string
}

export const Press: PressItem[] = [
    {
        title: "Mañá abrirase o prazo de inscrición para a VI edición da carreira nocturna SantYaGo10K",
        date: 1646996400,
        provider: "concello",
        source: "http://santiagodecompostela.gal/hoxe/nova.php?id_nova=21373&lg=gal"
    },
    {
        title: "VI Carrera SantYaGo10K en El Corte Inglés Compostela",
        date: 1648807200,
        provider: "eci",
        source: "https://www.elcorteingles.es/centroscomerciales/es/eci/centros/centro-comercial-compostela/eventos/vi-carrera-santyago-10k"
    },
];
