import React from "react";
import {Helmet} from 'react-helmet';

import {
    Container,
    Row,
    Col,
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {VerticalSponsors} from "../../modules/layout";


export const Clothes: React.FC = () => {
    const {formatMessage} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "during.clothes.title"})} | SantYaGo10K 2022</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 bg-gradient-orange">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "during.clothes.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "during.clothes.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/clothes.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <h1><FormattedMessage id="during.clothes.heading"/></h1>
                                    <Row>
                                        <Col lg="6">
                                            <p>
                                                <FormattedMessage
                                                    id="during.clothes.subheading1"
                                                    values={{
                                                        b: (chunks: React.ReactNode) => (
                                                            <span style={{fontWeight: "bold"}}>{chunks}</span>
                                                        ),
                                                    }}
                                                />
                                            </p>
                                            <p>
                                                <FormattedMessage
                                                    id="during.clothes.subheading2"
                                                    values={{
                                                        b: (chunks: React.ReactNode) => (
                                                            <span style={{fontWeight: "bold"}}>{chunks}</span>
                                                        ),
                                                    }}
                                                />
                                            </p>
                                            <p>
                                                <FormattedMessage
                                                    id="during.clothes.p3"
                                                    values={{
                                                        b: (chunks: React.ReactNode) => (
                                                            <span style={{fontWeight: "bold"}}>{chunks}</span>
                                                        ),
                                                    }}
                                                />
                                            </p>
                                        </Col>
                                        <Col lg="6">
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require('../../assets/img/undraw/wardrobe.png')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormattedMessage
                                                id="during.clothes.p1"
                                                values={{
                                                    p: (chunks: React.ReactNode) => (
                                                        <p>{chunks}</p>
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-5">
                                        <Col>
                                            <img
                                                alt="..."
                                                className="img-fluid shadow"
                                                src={require('../../assets/img/race/others/clothes.png')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6" className="order-lg-2">
                                            <p>{formatMessage({id: "during.clothes.p2"})}</p>
                                        </Col>
                                        <Col lg="6" className="order-lg-1">
                                            <img
                                                alt="..."
                                                className="img-fluid floating mt-1 shadow"
                                                src={require("../../assets/img/race/others/wardrobe.png")}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Clothes;
