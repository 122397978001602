import React from "react";
import {Helmet} from 'react-helmet';

import {Badge, Card, CardBody, Col, Container, Row,} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {VerticalSponsors} from "../../modules/layout";


export const Sleeping: React.FC = () => {
  const {formatMessage} = useIntl();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement !== null)
      document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Helmet>
        <title>{formatMessage({id: "after.sleeping.title"})} | SantYaGo10K 2022</title>
      </Helmet>
      <main>
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-lg section-shaped">
            <div className="shape shape-style-1 bg-gradient-green">
              <span/> <span/> <span/> <span/> <span/>
              <span/> <span/> <span/> <span/>
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    <h1 className="display-3 text-white">
                      {formatMessage({id: "after.sleeping.title"})}
                    </h1>
                    <p className="lead text-white">
                      {formatMessage({id: "after.sleeping.subtitle"})}
                    </p>
                  </Col>
                  <Col lg="6">
                    <img
                      alt="..."
                      className="img-fluid rounded shadow-lg"
                      src={require('../../assets/img/race/pages/sleeping.jpg')}
                    />
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
              >
                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
          <section className="section section-sm">
            <Container>
              <Row>
                <Col lg="8">
                  <Badge color="warning" pill className="mr-1">
                    <FormattedMessage id="wip.confirmation"/>
                  </Badge>
                  <h1><FormattedMessage id="after.sleeping.heading"/></h1>
                  <Row>
                    <Col lg="6">
                      <p><FormattedMessage id="after.sleeping.subheading"/></p>
                    </Col>
                    <Col lg="6">
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require('../../assets/img/undraw/sleeping.png')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p><FormattedMessage id="after.sleeping.p"/></p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <Card className="shadow shadow-sm mt-5">
                        <CardBody>
                          <div className="d-flex px-3">
                            <div className="pl-4">
                              <h5 className="title text-warning">
                                <i className="fa fa-star"/> Hotel Oca Porta do Camino
                              </h5>
                              <p>Rúa Miguel Ferro Caaveiro, S/N<br/>15703 - Santiago de Compostela</p>
                              <p><span className="fa fa-phone"/> (+34) 981 56 94 00</p>
                              <p><span className="fa fa-globe"/> <a
                                  href="https://www.ocahotels.com/hoteles/hotel-oca-puerta-del-camino/" target="_blank"
                                  rel="noopener noreferrer">ocahotels.com</a></p>
                              <p><span className="fa fa-envelope"/> <a
                                  href="mailto:reservas.puertadelcamino@ocahotels.es" target="_blank"
                                  rel="noopener noreferrer">reservas.puertadelcamino@ocahotels.es</a></p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col>
                      <h3><FormattedMessage id="after.sleeping.check"/></h3>
                      <Card className="shadow border-0">
                        <CardBody className="py-0 px-0 pr-0 pl-0 pt-0 pb-0" style={{height: 512}}>
                          <iframe
                            title="map"
                            src="https://www.google.com/maps/d/u/0/embed?mid=1IPhzODO8__MC3do0ob9Gxcoooh8ZKT9I&z=14"
                            width="100%"
                            height="512"
                            style={{border: "none"}}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col lg="4">
                  <VerticalSponsors/>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </main>
    </>
  );
};

export default Sleeping;
