import React, {useState} from "react";
import {Helmet} from 'react-helmet';

import {Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {useIntl} from "react-intl";
import {HorizontalSponsors} from "../../modules/layout";
import classnames from "classnames";


const Kms: { [id: number]: string[] } = {
    1: [
        'San Francisco',
        'Facultade de Medicina',
        'Campiño de San Francisco',
        'Avda. Xoan XXIII',
        'Avda. Burgo das Nacións'
    ],
    2: ['Avda. Castelao',
        'Rúa Vista Alegre',
        'Rúa Avío'
    ],
    3: [
        'Rúa Avío',
        'Rúa Vista Alegre',
        'Rúa Salvadas',
        'Rúa Morón',
        'Rúa Castiñeiros',
        'Campiño de San Francisco'
    ],
    4: [
        'Costa San Francisco',
        'Rúa Entrerríos',
        'Rúa Galeras',
        'Rúa Pombal'
    ],
    5: [
        'Paseo dos Leóns',
        'Paseo da Ferradura (1)',
        'Paseo da Ferradura (2)'
    ],
    6: [
        'Paseo Central da Alameda',
        'Campo da Estrela',
        'Avda. Xoan Carlos I',
        'Campo da Estrela',
        'Rúa Senra',
        'Praza de Galicia'
    ],
    7: [
        'Rúa Fonte de Santo Antonio',
        'Tránsito da Mercé',
        'Rúa Ensinanza',
        'Rúa Virxe da Cerca',
        'Rúa Astronóno Aller Ulloa',
        'Porta do Camiño',
        'Rúa Rodas'
    ],
    8: [
        'Rúa Algalia de Abaixo',
        'Rúa Casas Reais',
        'Rúa Travesa',
        'Rúa San Bieito',
        'Rúa Santo Agostiño',
        'Praza Santo Agostiño',
        'Rúa Ameas',
        'Praciña San Fiz de Solovio',
        'Travesa da Universidade',
        'Praza da Universidade',
        'Praza Mazarelos',
        'Rúa Cardeal Paiá',
        'Rúa Orfas',
        'Rúa Caldeireiría'
    ],
    9: [
        'Rúa Preguntoiro',
        'Praza Cervantes',
        'Rúa Acibechería',
        'Vía Sacra',
        'Rúa San Paio de Antealtares',
        'Praza Feixoo',
        'Rúa Conga',
        'Rúa Nova',
        'Cantón Toural',
        'Praza Toural',
        'Rúa Vilar',
        'Praza Praterías',
        'Rúa Fonseca'
    ]
};

export const Track: React.FC = () => {
    const {formatMessage} = useIntl();

    const [km, setKm] = useState(-1);
    let toggleNavs = (e: any, k: number) => {
        e.preventDefault();
        setKm(k);
    };

    React.useEffect(() => {
        if (km === -1) {
            document.documentElement.scrollTop = 0;
            if (document.scrollingElement !== null)
                document.scrollingElement.scrollTop = 0;
            setKm(1);
        }
    }, [km]);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "during.track.title"})} | SantYaGo10K 2022</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 bg-gradient-orange">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "during.track.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "during.track.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/track/start.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section">
                        <Container>
                            <Row className="row-grid align-items-center">
                                <Col className="order-md-2" md="4">
                                    <h4>{formatMessage({id: "during.track.circuit"})}</h4>
                                    <p>{formatMessage({id: "during.track.circuit.p1"})}</p>
                                    <p>{formatMessage({id: "during.track.circuit.p2"})}</p>
                                    <p>{formatMessage({id: "during.track.circuit.p3"})}
                                        <img src={require('../../assets/img/race/track/button.png')}
                                             alt="..."/></p>
                                    <p><strong
                                        style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.start"})}: </strong>San
                                        Francisco</p>
                                    <p><strong
                                        style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.finish"})}: </strong> Praza
                                        do Obradoiro</p>
                                </Col>
                                <Col className="order-md-1" md="8">
                                    <Card className="shadow border-0">
                                        <CardBody className="py-0 px-0 pr-0 pl-0 pt-0 pb-0" style={{height: 512}}>
                                            <iframe
                                                title="map"
                                                src="https://www.google.com/maps/d/u/0/embed?mid=1IPhzODO8__MC3do0ob9Gxcoooh8ZKT9I&z=14"
                                                width="100%"
                                                height="512"
                                                style={{border: "none"}}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section section-sm">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg="12">
                                    {/* Tabs with icons */}
                                    <div className="">
                                        <small className="text-uppercase font-weight-bold">
                                            {formatMessage({id: "during.track.km2km"})}
                                        </small>
                                    </div>
                                    <Row>
                                        <Col lg="6">
                                            <div className="nav-wrapper">
                                                <Nav
                                                    className="nav-fill flex-column flex-md-row"
                                                    id="tabs-icons-text"
                                                    pills
                                                    role="tablist"
                                                >
                                                    <NavItem>
                                                        <Row>
                                                            <Col>
                                                                <NavLink
                                                                    aria-selected={km === 0}
                                                                    className={classnames("mb-sm-3 mb-md-0", {
                                                                        active: km === 0
                                                                    })}
                                                                    onClick={e => toggleNavs(e, 0)} href="#"
                                                                    role="tab"
                                                                >
                                                                    {formatMessage({id: "during.track.start"})} (PK 0)
                                                                </NavLink>
                                                            </Col>
                                                            <Col>
                                                                <NavLink
                                                                    aria-selected={km === 10}
                                                                    className={classnames("mb-sm-3 mb-md-0", {
                                                                        active: km === 10
                                                                    })}
                                                                    onClick={e => toggleNavs(e, 10)} href="#"
                                                                    role="tab"
                                                                >
                                                                    {formatMessage({id: "during.track.finish"})} (PK 10)
                                                                </NavLink>
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            {Object.keys(Kms).map((key, index) => (
                                                                <Col xl="4" lg="4" md="4" sm="4" xs="4"
                                                                     className="mb-2">
                                                                    <NavLink
                                                                        aria-selected={km === parseInt(key)}
                                                                        className={classnames("mb-sm-3 mb-md-0", {
                                                                            active: km === parseInt(key)
                                                                        })}
                                                                        onClick={e => toggleNavs(e, parseInt(key))}
                                                                        href="#"
                                                                        role="tab"
                                                                    >
                                                                        PK {parseInt(key)}
                                                                    </NavLink>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <Row>
                                                <Col span={12}>
                                                    <Card className="shadow">
                                                        <CardBody>
                                                            <TabContent activeTab={"km" + km.valueOf()}>
                                                                <TabPane tabId="km0">
                                                                    <p className="description">
                                                                        <li>San Francisco</li>
                                                                    </p>
                                                                </TabPane>
                                                                <TabPane tabId="km10">
                                                                    <p className="description">
                                                                        <li>Praza do Obradoiro</li>
                                                                    </p>
                                                                </TabPane>
                                                                {Object.keys(Kms).map(key => (
                                                                    <TabPane tabId={"km" + key}>
                                                                        <p className="description">
                                                                            {Kms[parseInt(key)].map(value => (
                                                                                <li>{value}</li>
                                                                            ))}
                                                                        </p>
                                                                    </TabPane>
                                                                ))}
                                                            </TabContent>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col span={12}>
                                                    <Card className="shadow border-0">
                                                        <CardBody className="py-0 px-0 pr-0 pl-0 pt-0 pb-0"
                                                                  style={{height: 256}}>
                                                            <iframe width="100%" height="256"
                                                                    src="https://www.youtube.com/embed/iVFajuI1psQ"
                                                                    title="Recorrido - SantYaGo10K" frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen/>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section">
                        <Container>
                            <Row>
                                <Col>
                                    <h1>{formatMessage({id: "during.track.alt"})}</h1>
                                    <Row>
                                        <Col>
                                            <Card className="shadow border-0">
                                                <CardBody>
                                                    <Row>
                                                        <Col lg="6">
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.total"})}:</strong> 10
                                                                km <small>(10,000 m)</small></p>
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.lowest"})}</strong>:
                                                                229 m <small>(PK 4.05)</small></p>
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.uphill"})}:</strong> 4.68
                                                                km <small>(44.5 %)</small></p>
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.flat"})}:</strong> 1.35
                                                                km <small>(12.8 %)</small></p>
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.highup"})}:</strong> +12.2
                                                                % <small>(PK 3.42)</small></p>
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.longup"})}:</strong> 1.53
                                                                km <small>(PK 6.75)</small></p>
                                                        </Col>
                                                        <Col lg="6">
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.totalud"})}:</strong> 148
                                                                m / 152 m</p>
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.highest"})}</strong>:
                                                                281 m <small>(PK 8.28)</small></p>
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.lowing"})}:</strong> 4.41
                                                                km <small>(41.9 %)</small></p>
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.gain"})}:</strong> 52
                                                                m</p>
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.highdown"})}:</strong> -10
                                                                % <small>(PK 0.81)</small></p>
                                                            <p><strong
                                                                style={{fontWeight: "bold"}}>{formatMessage({id: "during.track.alt.longdown"})}:</strong> 0.81
                                                                km <small>(PK 2.43)</small></p>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <small className="d-block text-uppercase font-weight-bold mt-3">
                                                {formatMessage({id: "during.track.alt.meters"})}
                                            </small>
                                            <img
                                                alt="..."
                                                className="img-fluid rounded shadow"
                                                src={require('../../assets/img/race/track/altimetry_e.png')}/>
                                            <small className="d-block text-uppercase font-weight-bold mt-3">
                                                {formatMessage({id: "during.track.alt.percentage"})}
                                            </small>
                                            <img
                                                alt="..."
                                                className="img-fluid rounded shadow"
                                                src={require('../../assets/img/race/track/altimetry_p.png')}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <HorizontalSponsors/>
                </div>
            </main>
        </>
    );
};

export default Track;
