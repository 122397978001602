import React from "react";
import { Helmet } from 'react-helmet';

import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";
import {useIntl} from "react-intl";
import {Link} from 'react-router-dom';
import {AppRoute, AppRouteTitles} from "../const";
import {Route} from "../modules/i18n";

export const NotFound: React.FC = () => {
    const {formatMessage} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>404 | SantYaGo10K 2022</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped pb-250">
                        <div className="shape shape-style-1 shape-orange">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "error.404.heading"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "error.404.subheading"})}
                                        </p>
                                        <div className="btn-wrapper">
                                            <Button
                                                className="btn-white btn-icon mb-3 mb-sm-0 ml-1 text-orange"
                                                color="default"
                                                to={Route(AppRoute.Home)}
                                                tag={Link}
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fa fa-home" />
                                                </span>
                                                <span className="btn-inner--text">
                                                    {formatMessage({id: AppRouteTitles.get(AppRoute.Home)})}
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require("../assets/img/race/404/img.jpg")}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                </div>
            </main>
        </>
    );
};

export default NotFound;
