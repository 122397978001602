import React, {useRef} from "react";
import {Helmet} from 'react-helmet';

import {Badge, Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {HorizontalSponsors} from "../../modules/layout";


const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
export const PastYears: React.FC = () => {
  const {formatMessage} = useIntl();
  const sec = useRef(null);
  const executeScroll = () => scrollToRef(sec);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement !== null)
      document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Helmet>
        <title>{formatMessage({id: "info.pastyears.title"})} | SantYaGo10K 2022</title>
      </Helmet>
      <main>
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 bg-gradient-default">
              <span/> <span/> <span/> <span/> <span/>
              <span/> <span/> <span/> <span/>
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    <h1 className="display-3 text-white">
                      {formatMessage({id: "info.pastyears.heading"})}
                    </h1>
                    <p className="lead text-white">
                      {formatMessage({id: "info.pastyears.subheading"})}
                    </p>
                    <div className="btn-wrapper">
                      <Button
                        className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                        color="default"
                        onClick={executeScroll}
                      >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fa fa-caret-down"/>
                                                </span>
                        <span className="btn-inner--text">
                                                    {formatMessage({id: "info.pastyears.more"})}
                                                </span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
              >
                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
          <section className="section pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-warning text-uppercase">
                            <del>{formatMessage({id: 'info.pastyears.year'})} 2020</del>
                            <span style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                            <Badge color="warning" pill className="mr-1">
                              <del><FormattedMessage
                                id="info.pastyears.ed.6"
                                values={{
                                  sup: (chunks: React.ReactNode) => (
                                    <sup>{chunks}</sup>
                                  ),
                                }}
                              /></del>
                            </Badge>
                          </h6>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="https://2020.santyago10k.run"
                            target="_blank"
                          >
                            2020.santyago10k.run
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-warning text-uppercase">
                            {formatMessage({id: 'info.pastyears.year'})} 2019
                            <span style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                            <Badge color="warning" pill className="mr-1">
                              <FormattedMessage
                                id="info.pastyears.ed.5"
                                values={{
                                  sup: (chunks: React.ReactNode) => (
                                    <sup>{chunks}</sup>
                                  ),
                                }}
                              />
                            </Badge>
                          </h6>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="https://2019.santyago10k.run"
                            target="_blank"
                          >
                            2019.santyago10k.run
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-warning text-uppercase">
                            {formatMessage({id: 'info.pastyears.year'})} 2018
                            <span style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                            <Badge color="warning" pill className="mr-1">
                              <FormattedMessage
                                id="info.pastyears.ed.4"
                                values={{
                                  sup: (chunks: React.ReactNode) => (
                                    <sup>{chunks}</sup>
                                  ),
                                }}
                              />
                            </Badge>
                          </h6>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="https://2018.santyago10k.run"
                            target="_blank"
                          >
                            2018.santyago10k.run
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-warning text-uppercase">
                            {formatMessage({id: 'info.pastyears.year'})} 2017
                            <span style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                            <Badge color="warning" pill className="mr-1">
                              <FormattedMessage
                                id="info.pastyears.ed.3"
                                values={{
                                  sup: (chunks: React.ReactNode) => (
                                    <sup>{chunks}</sup>
                                  ),
                                }}
                              />
                            </Badge>
                          </h6>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="https://2017.santyago10k.run"
                            target="_blank"
                          >
                            2017.santyago10k.run
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-warning text-uppercase">
                            {formatMessage({id: 'info.pastyears.year'})} 2016
                            <span style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                            <Badge color="warning" pill className="mr-1">
                              <FormattedMessage
                                id="info.pastyears.ed.2"
                                values={{
                                  sup: (chunks: React.ReactNode) => (
                                    <sup>{chunks}</sup>
                                  ),
                                }}
                              />
                            </Badge>
                          </h6>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="https://2016.santyago10k.run"
                            target="_blank"
                          >
                            2016.santyago10k.run
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-warning text-uppercase">
                            {formatMessage({id: 'info.pastyears.year'})} 2015
                            <span style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                            <Badge color="warning" pill className="mr-1">
                              <FormattedMessage
                                id="info.pastyears.ed.1"
                                values={{
                                  sup: (chunks: React.ReactNode) => (
                                    <sup>{chunks}</sup>
                                  ),
                                }}
                              />
                            </Badge>
                          </h6>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="https://2015.santyago10k.run"
                            target="_blank"
                          >
                            2015.santyago10k.run
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section" ref={sec}>
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="4">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("../../assets/img/undraw/start.png")}
                  />
                </Col>
                <Col className="order-md-1" md="8">
                  <div className="pr-md-5">
                    <h3>{formatMessage({id: "info.pastyears.origin"})}</h3>
                    <FormattedMessage
                      id="info.pastyears.origins"
                      values={{
                        p: (chunks: React.ReactNode) => (
                          <p>{chunks}</p>
                        ),
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <HorizontalSponsors/>
        </div>
      </main>
    </>
  );
};

export default PastYears;
