import React from "react";
import {Helmet} from 'react-helmet';

import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {useIntl} from "react-intl";
import {HorizontalSponsors} from "../../modules/layout";


export const Photos: React.FC = () => {
    const {formatMessage, locale} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, [locale,]);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "after.photos.title"})} | SantYaGo10K 2022</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 shape-orange">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "after.photos.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "after.photos.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/photos.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    <section className="section">
                        <Container>
                            <Row className="row-grid align-elements-center">
                                <Col span={12}>
                                    <Card className="shadow">
                                        <CardBody>
                                            <p className="description">
                                                <li><a
                                                    href="https://photos.app.goo.gl/KX6Qvwo2nwTZEeZj7"
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    Juanma Fotografía
                                                </a></li>
                                                <li><a
                                                    href="https://www.facebook.com/media/set/?set=a.2184346061732500"
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    RunPhotos Galicia
                                                </a></li>
                                                <li><a
                                                    href="https://www.facebook.com/VERunners/photos/pcb.1447553005665048/1447502935670055/"
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    VERRunners
                                                </a></li>
                                                <li><a
                                                    href="https://www.fotocampeon.com/evento.php?idEvento=10&desc=SantYaGo10K"
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    Foto Campeón
                                                </a></li>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <HorizontalSponsors/>
                </div>
            </main>
        </>
    );
};

export default Photos;
