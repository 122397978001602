const baseStrings = {
    /** Languages*/
    'lang': 'Idioma',
    'lang.ca': 'Galego',
    'lang.es': 'Español',
    'lang.en': 'English',

    /** Work In Progress */
    'wip.subtitle': 'Estamos a traballar nesta páxina. Mentres tanto, podes botarlle unha ollada a antiga.',
    'wip.confirmation': 'Pendente de confirmación',

    /** Routes */
    'routes.home': '/',
    'routes.before': '/antes',
    'routes.before.join': '/inscribirse',
    'routes.before.dorsal': '/antes/dorsais',
    'routes.before.getto': '/antes/chegar',
    'routes.before.parking': '/antes/aparcar',
    'routes.before.eating': '/antes/comer',
    'routes.before.chip': '/antes/chip',
    'routes.before.bag': '/antes/bolsa',
    'routes.during': '/durante',
    'routes.during.track': '/durante/percorrido',
    'routes.during.clothes': '/durante/gardarroupa',
    'routes.during.drinking': '/durante/avituallamento',
    'routes.during.showers': '/durante/duchas',
    'routes.during.prizes': '/durante/premios',
    'routes.after': '/despois',
    'routes.after.results': '/resultados',
    'routes.after.photos': '/fotos',
    'routes.after.dinner': '/despois/cear',
    'routes.after.sleeping': '/despois/dormir',
    'routes.after.visiting': '/despois/visitar',
    'routes.info': '/outros',
    'routes.info.pastyears': '/anteriores',
    'routes.info.regulation': '/regulamento',
    'routes.info.terms': '/termos',
    'routes.info.press': '/prensa',
    'routes.volunteer': '/voluntarios',
    'routes.contact': '/contacto',
    'routes.admin': '/admin',
    'routes.admin.calendar': '/admin/calendar',

    /** Page titles */
    'home.title': 'Inicio',
    'before.title': 'O Antes',
    'before.join.title': 'Inscribirse',
    'before.dorsal.title': 'Recollida de dorsais',
    'before.getto.title': 'Como chegar',
    'before.parking.title': 'Onde aparcar',
    'before.eating.title': 'Onde comer',
    'before.chip.title': 'Como colocar o chip',
    'before.bag.title': 'Bolsa do corredor',
    'during.title': 'O Durante',
    'during.track.title': 'Percorrido',
    'during.clothes.title': 'Gardarroupa',
    'during.drinking.title': 'Avituallamento',
    'during.showers.title': 'Duchas',
    'during.prizes.title': 'Premios',
    'after.title': 'O Despois',
    'after.results.title': 'Resultados',
    'after.photos.title': 'Fotos',
    'after.dinner.title': 'Onde cear',
    'after.sleeping.title': 'Onde durmir',
    'after.visiting.title': 'Que visitar',
    'info.title': 'Info',
    'info.pastyears.title': 'Anos Anteriores',
    'info.regulation.title': 'Regulamento',
    'info.terms.title': 'Termos e Condicións',
    'info.press.title': 'Prensa',
    'volunteer.title': 'Voluntarios',
    'contact.title': 'Contacto',

    /** Navbar */
    'nav.before.getto': 'Unha guía rápida sobre como chegar á capital galega',
    'nav.before.parking': 'Ónde deixar o coche durante a carreira o máis barato posible',
    'nav.before.eating': 'Lugares para prepararse para a carreira',
    'nav.before.chip': 'Cómo colocar correctamente o chip na zapatilla',
    'nav.before.bag': 'Qué vai contener a bolsa dos participantes',
    'nav.during.track': 'Todo o percorrido, nun mapa interactivo con altitudes',
    'nav.during.clothes': 'Podes deixar as túas pertenzas a salvo con nós',
    'nav.during.drinking': 'Ónde poderás repoñer líquidos na carreira',
    'nav.during.showers': 'Varias instalacións á túa disposición para asearte',
    'nav.during.prizes': 'Ao rematar haberá sorteos e premios para todos',
    'nav.after.photos': 'Unha galería de imaxes da carreira',
    'nav.after.dinner': 'Lugares onde cear coma un campión',
    'nav.after.sleeping': 'Lugares para descansar tras a carreira',
    'nav.after.visiting': 'Lugares interesantes para visitar en Santiago',

    /** Error */
    'error.404.heading': 'Páxina non atopada',
    'error.404.subheading': 'Vaia, non atopamos esta páxina. Ao mellor a podes atopar no Inicio?',

    /** Footer */
    'footer.heading': "Carreira Nocturna SantYaGo10K",
    'footer.subheading': "Contacta con nós se tes algunha dúbida",

    /** Sponsors */
    'sponsors': "Patrocinan",
    'sponsors.organizers': "Organizan",
    'sponsors.partners': "Colaboran",

    /** Generic */
    'generic.moving.title': 'Como moverse pola cidade',
    'generic.moving.subtitle': 'Santiago é unha cidade relativamente pequena; poderás ir a calqueira lado camiñando. ' +
        'Senón, sempre podes coller un autobús urbano ou un taxi.',
    'generic.eatingdinner.title': 'Ñam Ñam',
    'generic.eatingdinner.subtitle': 'Galicia é famosa pola sua rica gastronomía. E Santiago como boa capital galega ' +
        'non ía ser menos. No casco antigo atópanse unha gran variedade de restaurantes onde sirven comida típica ' +
        'galega en abundancia.',
    'generic.eatingdinner.p1': 'Aproveita o día da carreira, achégate a Santiago pola mañá, recolle o teu dorsal e ' +
        'da unha volta pola cidade. Come algo nalgún dos nosos restaurantes patrocinadores e aproveita as súas ofertas.',
    'generic.eatingdinner.p2': 'Lembra ademais que a festa terminará a medianoite, polo que aquí vos damos unha relación de ' +
        'restaurantes que se axustan a calquera condición e ambiente que busques.',
    'generic.eatingdinner.p3': 'Todos estes colaboradores estante esperando para ofrecerche, a ti e ós teus ' +
        'acompañantes, unha serie de vantaxes por ser participante da SantYaGo10K.',
    'generic.eatingdinner.open': 'Luns-Domingo',
    'generic.eatingdinner.check': 'Mira no mapa a situación destes resturante (desplegando o menú)',

    /** Home */
    'home.heading': '30 de Abril ás 22:00',
    'home.subheading': 'Non a podes perder!',
    'home.summary1': 'Corre polas zonas emblemáticas da cidade histórica de Santiago de Compostela. Un circuito ' +
        'nocturno pola zona vella da cidade que nunca defrauda.',
    'home.summary2': 'Apoia o deporte para as persoas con discapacidade',
    'home.track.heading': 'O noso circuito',
    'home.track.subheading': 'Con un desnivel máximo de tan só 53 metros',
    'home.track.description': 'Lembrade que o trazado será de 10 kilómetros e está homologado pola Real Federación ' +
        'Española de Atletismo, coa garantía de que a medición do circuíto é completamente fiable. Deste xeito a tua ' +
        'marca será válida para outras competicións oficiais.',
    'home.track.more': 'Ver o circuito completo',
    'home.intro.title': 'Presentación',
    'home.intro.subtitle': 'Repasa o vídeo da edición do 2017 para ter unha idea do moito que se disfruta na SantYaGo10K.',
    'home.intro.l1': '6º Edición',
    'home.intro.l2': 'A partir de 18 anos',
    'home.intro.l3': 'Éxito asegurado',
    'home.join.price': 'Prezo',
    'home.join.regular': 'Regular',
    'home.join.last': 'Última chamada',
    'home.join.not': 'Aínda non',
    'home.join.yes': 'Aberto',
    'home.join.closed': 'Pechado',
    'home.join.discounts': 'Temos descontos dispoñibles',
    'home.showers.description': 'Despois de dalo todo, tocará picar algo pola zona. Pero antes unha ducha para ' +
        'refrescarse, no Campo de Fútbol do Complexo Deportivo Santa Isabel (que ademais queda ao lado do parking) ou ' +
        'no Pavillón Municipal Lorenzo da Torre (moi preto do gardarroupa).',
    'home.clothes.description': 'Tamén dispoñemos de gardarroupa na praza do Obradoiro. Queremos que só penses en ' +
        'pasalo ben e en correr sen preocupacións.',
    'home.parking.description': 'O día da proba dende as 20:00 ate as 3:00 poderás aparcar no Parking da rúa ' +
        'Galeras (Parking Catedral) por un custe total de 3€ por 7 horas de estacionamento.',
    'home.dorsal.description': 'Poderás recollelo o día antes ou na mañá da carreira en El Corte Inglés, ou pola ' +
        'tarde na propia Praza do Obradoiro.',
    'home.circuit.title': 'III Circuito de Carreiras Ciudades Patrimonio da Humanidade',
    'home.circuit.description': 'Sabemos que moitos de vós sodes uns viaxeiros imparables. Por eso, como ano tras ' +
        'ano, a SantYaGo10k atópase no "<a>Circuito de Carreiras Ciudades Patrimonio da Humanidade</a>".',
    'home.seemore': 'Ver máis info',
    'home.2023.info': 'Xa temos data para a 7ª edición da SantYaGo10K. Ven correla un ano máis!',
    'home.2023.newdate': 'Sábado, 29 de Abril do 2023',
    'home.2023.seemore': 'Ler máis',
    'home.2023.close': 'Pechar',

    /** Join */
    'before.join.subtitle': 'Estás moi preto de formar parte dunha noite moi especial! Só por inscribirte xa entrarás ' +
        'en moitos sorteos de agasallos.',
    'before.join.now': 'Apúntame xa!',
    'before.join.regular': 'Inscripción Regular',
    'before.join.regular.description': 'Para todos aqueles que vos gusta planear as cousas con tempo, podedes desfrutar de ' +
        'varias semanas para decidirvos.',
    'before.join.lastcall': 'Inscripción In-Extremis',
    'before.join.lastcall.description': 'Para os que sodes máis de esperar ata o último momento, non vos quedaredes ' +
        'sen participar por ese motivo. Recordade que o prezo dos 12€ manterase ate completar os 1500 primeiros ' +
        'participantes.',
    'before.join.not': 'Aínda non abrimos as inscripcións; podes atopar un pouco máis arriba os prazos.',
    'before.join.important.iframe': 'En caso de que apareza un erro pedindo recargar a páxina porque pasou ' +
        'demasiado tempo, <a>diríxete a esta páxina</a> para realizar a inscripción.',
    'before.join.important': 'Importante',
    'before.join.important.finish': 'Unha inscripción considérase finalizada só cando se completa o pagamento',
    'before.join.important.price': 'O prezo será o do momento de facer o pago, non o de cubrir os datos',
    'before.join.physical': 'Pago presencial',
    'before.join.physical.description': '<p>Se queres facer o pagamento con diñeiro en efectivo, non cubras este ' +
        'formulario. Terás que acercar ao Multiusos, a Santa Isabel ou a El Corte Ingles para facer a inscripción e ' +
        'o pago.</p>' +
        '<p>Tamén tes a opción de rexistrarte a través do Paso de Enxeñaría Informática de USC, onde permiten pagos ' +
        'presenciais.</p>',
    'before.join.discount': 'Descontos',
    'before.join.discount.description': '<p>Os abonados ao Multiusos Fontes do Sar e o CD Santa Isabel disfrutarán ' +
        'dun desconto de 2€ na inscripción. Para aplicalo, terán que facer a inscripción nos propios ximnasios, sen ' +
        'cubrir este formulario.</p><p>Os participantes do ano pasado gozarán dun pequeno desconto (remitíuselles ' +
        'máis información por correo electrónico).</p>',

    /** GetTo */
    'before.getto.subtitle': 'Santiago de Compostela: Cidade Patrimonio da Humanidade',
    'before.getto.heading': 'Non te perdas',
    'before.getto.subheading': 'A pesares de non ser unha cidade moi grande, sabemos que chegar a Santiago pode ' +
        'ser algo complicado. Porén poñemos esta pequena guía a vosa disposición, para que os que vides de fora o ' +
        'teñades un chisco máis fácil aistir á carreira.',
    'before.getto.car.title': 'En Coche',
    'before.getto.train.title': 'En Tren',
    'before.getto.plane.title': 'En Avión',
    'before.getto.car.description.p1': 'Botádelle unha ollada os consellos que hai en <a>i</a> para aforrar diñeiro ' +
        'se chegas co coche.',
    'before.getto.car.description.p2': 'Lembrade que cando remate a carreira teremos un expectáculo que non debes ' +
        'perder e que, tal vez, che compense quedar até o domingo. Se é o teu caso non perdas as ofertas do apartado ' +
        '<a>i</a>.',
    'before.getto.train.description': '<a>RENFE</a> ofrece varias posibilidades de achegarte en tren, con moita frecuencia ' +
        'nalgunhas liñas.',
    'before.getto.plane.flight': 'Combinacións de vuelos nacionais e internacionais',
    'before.getto.plane.taxi.commute': 'Transporte dende o aeroporto á cidade',
    'before.getto.plane.bus': 'Autobús (<a>Empresa Freire</a>): Líña Aeroporto-Ciudad-Aeroporto',
    'before.getto.plane.bus.price': 'Ida: 3,00€ | Ida e volta: 5,10€',
    'before.getto.plane.bus.l1': 'Aeroporto > Santiago: 07:00-01:00',
    'before.getto.plane.bus.l2': 'Santiago > Aeroporto: 06:00-00:00',
    'before.getto.plane.bus.freq': 'cada 30 minutos',
    'before.getto.plane.taxi': 'Taxis (<a>Radio Taxi Compostela</a>)',
    'before.getto.plane.taxi.price': 'Aeroporto-Santiago: 21,00€ aprox',
    'before.getto.plane.taxi.l1': 'Parada Taxis Aeroporto: 981 591 575',
    'before.getto.plane.taxi.l2': 'Radio-Taxi: 981 56 92 92',

    /** Parking */
    'before.parking.subtitle': 'Se eres corredor, temos descontos en aparcamento na cidade. Non o deixes pasar!',
    'before.parking.heading': 'Non vas ter problema',
    'before.parking.subheading': 'Santiago é unha cidade famosa por non ter moitas prazas de aparcamento ao aire libre. ' +
        'Porén, recomendamos o seguinte aparcamento no que ademáis gozaredes dunha tarifa enormemente reducida.',
    'before.parking.catedral.p1': 'O día da carreira, entre as 20:00 do mesmo día e as 03:00 do día siguiente, ' +
        'poderedes disfrutar dunha tarifa reducida. <b>7 horas por tan solo 3€</b> (en caso de superar ese ' +
        'horario, para o resto aplicarase a tarifa normal).',
    'before.parking.catedral.p2': 'Para poder disfrutar da oferta o cliente deberá pasar pola Oficina de Control ' +
        '(e non pagar directamente nas máquinas), unha vez estacionado ou antes de abandonar as instalacións e ' +
        'acreditar co Dorsal que participa na SantYaGo10K.',
    'before.parking.catedral.f1': '400 plazas de aparcamento dispoñibles para a carreira',
    'before.parking.catedral.f2': 'Aberto 24 horas',
    'before.parking.catedral.f3': 'A 200 metros da catedral e do casco antiguo',
    'before.parking.catedral.f4': 'Custodia de bicicletas, motos e equipaxes',
    'before.parking.catedral.f5': 'Servicio de alcoholímetros e préstamos de paraugas',
    'before.parking.catedral.f6': 'Prazas especiais para minusválidos e familias con carriños',
    'before.parking.catedral.f7': 'Wifi gratuíto para clientes',
    'before.parking.catedral.contact': 'Contacto',
    'before.parking.catedral.t1': 'O prezo habitual de 7 horas de estacionamiento é de 11,10€, coa promoción 3,00€.',
    'before.parking.catedral.t2': 'Unha vez excedido o tempo en promoción, aplicarase a tarifa normal por minuto do Parking.',

    /** Eating */
    'before.eating.subtitle': 'Antes da carreira hai que coller forzas. Aquí van as nosas recomendacións.',

    /** Chip */
    'before.chip.subtitle': 'Unha carreira non é unha carreira sen o seu debido cronometraxe... Como a SantYaGo10K ' +
        'está homologada pola RFEA, usamos sistemas de medición de competición para garantizar os máximos estándares.',
    'before.chip.heading': 'Colocación do chip',
    'before.chip.subheading': 'Na <a>i</a> entrégase unha bolsa coa identificación, un <b>dorsal</b> e unha <b>tarxeta ' +
        'de cronometraxe</b> ou <b>chip</b>. Na carreira deberase empregar esa tarxeta, non servindo ningún outro ' +
        'dispositivo de cronometraxe que o corredor teña en propiedade. Esa tarxeta identifica unívocamente ó ' +
        'número de dorsal ó que acompaña, sendo persoal e intransferible.',
    'before.chip.p1': 'O chip entregado será <b>retornable</b> polo que é <b>moi importante</b> que, unha vez ' +
        'finalizada a proba, todos os participantes o <b>devolvan</b> nas zonas de recollida que atoparán na Praza do ' +
        'Obradoiro. A non devolución ou perda do chip identificativo supón un custo organizativo que será repercutido ' +
        'ao titular do mesmo; rogamos a vosa colaboración neste aspecto.',
    'before.chip.p2': 'A tarxeta deberá ir colocada nos <b>cordóns da zapatilla</b>, ou enganchado a eles por ' +
        'medio de bridas. Non se garantiza o seu funcionamento en caso de portalo por riba dos tobillos. ' +
        'Xunto co chip atoparedes <b>dous pequenos fios de arame</b>, que vos axudarán a atar o chip á zapatilla ' +
        'sen ter que sacar os cordóns.',

    /** Bag */
    'before.bag.subtitle': 'Uns pequenos agasallos para todos os corredores, para desfrutar aínda máis da carreira.',
    'before.bag.heading': 'Para os corredores',
    'before.bag.subheading': 'Cando acudades á <a>i</a> se vos entregará a bolsa do corredor. Nela atoparedes o ' +
        'dorsal (no que vai integrado o chip) e unha serie de agasallos.',

    /** Track */
    'during.track.subtitle': 'Un percorrido polo casco monumental da capital galega. Que máis se pode pedir?',
    'during.track.circuit': 'O circuito',
    'during.track.circuit.p1': 'A extensión total do circuito é de 10.000 metros, 10 kms, e está homologado pola Real ' +
        'Federación Española de Atletismo',
    'during.track.circuit.p2': 'Neste plano pódese ver tanto o trazado coma os puntos clave (saída, puntos kilométricos...), ' +
        'e lugares de interese (duchas, restaurantes, hostais...)',
    'during.track.circuit.p3': 'Podes ocultar e mostrar o menú pulsando neste botón no mapa: ',
    'during.track.start': 'Saída',
    'during.track.finish': 'Meta',
    'during.track.km2km': 'Kilómetro a Kilómetro',
    'during.track.alt': 'Altimetría',
    'during.track.alt.total': 'Distancia total',
    'during.track.alt.lowest': 'Punto máis baixo',
    'during.track.alt.uphill': 'En subida',
    'during.track.alt.flat': 'En chan',
    'during.track.alt.highup': 'Subida máis acusada',
    'during.track.alt.longup': 'Subida máis longa',
    'during.track.alt.highdown': 'Baixada máis acusada',
    'during.track.alt.longdown': 'Baixada máis longa',
    'during.track.alt.totalud': 'Total Ascenso / Descenso',
    'during.track.alt.highest': 'Punto máis alto',
    'during.track.alt.lowing': 'En descenso',
    'during.track.alt.gain': 'Ganancia en altura',
    'during.track.alt.meters': 'Desnivel en metros',
    'during.track.alt.percentage': 'Desnivel en porcentaxe',

    /** Drinking */
    'during.drinking.subtitle': 'Todos os corredores gozaredes do voso debido avituallamento. En resumo: teredes ' +
        'á metade do percorrido un refrixerio para repoñer forzas.',
    'during.drinking.heading': 'Hidratarse é importatísimo',
    'during.drinking.subheading': 'Os corredores disporán dun punto de avituallamiento líquido con auga e bebida ' +
        'isotónica no Paseo dos Leóns da Alameda, xusto na metade do percorrido.',
    'during.drinking.p1': 'Avituallamento coa colaboración dos nosos patrocinadores:',
    'during.drinking.p2': 'Ademais, unha vez cruzada a liña de meta, disporase dun completo avituallamiento líquido ' +
        'e sólido na Praza do Obradoiro, onde poderedes atopar algunha sorpresa.',

    /** Clothes */
    'during.clothes.subtitle': 'Para que só vos preocupedes de correr, poñemos á vosa disposición un servizo de ' +
        'gardarroupa totalmente de balde para os corredores.',
    'during.clothes.heading': 'A bo recaudo',
    'during.clothes.subheading1': 'Habilitaremos o guardarroupa nos soportais do <b>Pazo de Raxoi</b>, na <b>Praza do ' +
        'Obradoiro</b>, xunto á liña de meta e moi próximo á liña de saída.',
    'during.clothes.subheading2': 'O servizo estará dispoñible entre as <b>20:30</b> e as <b>24:00</b>.',
    'during.clothes.p1': '<p>Pregamos que traias a tua bolsa ou mochila marcada coa pegatina que leva o teu número de ' +
        'dorsal. Isto facilitaranos a tarefa de localización na recollida e poderemos evitar esperas.</p><p>Aquí vai un ' +
        'exemplo de como empregar a pegatina do roupeiro:</p>',
    'during.clothes.p2': 'Se non levas mochila darémosche unha bolsa plástica na que poderás meter as túas ' +
        'pertenzas. Se tes a pegatina porémoslla, se non a levas rotularemos o teu número de dorsal e quedará ' +
        'gardada ata que vaias a recollela despois da carreira.',
    'during.clothes.p3': '<b>Será imprescindible que tanto na entrega ' +
        'como na recollida leves contigo o teu dorsal</b>. Só deste xeito poderemos recoller ou devolverche o que ' +
        'deixes.',

    /** Showers */
    'during.showers.subtitle': 'Tras rematar unha carreira non hai nada mellor ca unha boa ducha para refrescarse ' +
        'tras todo o esforzo feito. A SantYaGo10K pon de balde varias localizacións de aseo para os corredores.',
    'during.showers.heading': 'Onde ducharse',
    'during.showers.subheading': 'Unha vez crucedes a liña de meta, posiblemente o que máis vos apeteza sexa unha boa ' +
        'ducha para asearte. Porén, ao finalizar a carreira, atoparedes un completo servizo de duhas en dúas localizacións ' +
        'estratéxicas para facilitar a loxística.',
    'during.showers.time': 'Horario',
    'during.showers.address': 'Dirección',
    'during.showers.isabel': 'Campo de Santa Isabel',
    'during.showers.isabel.title': 'Campo de Fútbol no Complexo Deportivo Santa Isabel',
    'during.showers.isabel.p': 'Lembrámosvos que temos un convenio co Parking Catedral, que está situado moi preto ' +
        'destas instalacións, por se queredes deixar alí o coche. <a>i</a>.',
    'during.showers.lorenzo': 'Pavillón Lorenzo da Torre',
    'during.showers.lorenzo.title': 'Pavillón Municipal Lorenzo da Torre',
    'during.showers.lorenzo.p': 'Estas duchas están prácticamente ao lado da Praza do Obradoiro, na meta. Atoparedesvos ' +
        'moi preto do <a>i</a>.',

    /** Prizes */
    'during.prizes.subtitle': 'Todo esforzo ten a súa recompensa. Repartimos moitísimos premios entre todos os ' +
        'participantes; seguro que algún che cae!',
    'during.prizes.heading': 'Intentamos repartir o máximo',
    'during.prizes.subheading': 'No día da carreira, trala publicación de resultados no taboleiro de anuncios, ' +
        'farase a entrega de premios no estrado instalado na Praza do Obradoiro.',
    'during.prizes.p': 'Os atletas premiados deberán atoparse na zona do pódium e estar atentos á súa chamada no ' +
        'momento que se faga o anuncio polos altofalantes. Será necesario presentar o DNI ou pasaporte a efectos de ' +
        'identificación.',
    'during.prizes.masc': 'Categoría masculina',
    'during.prizes.fem': 'Categoría feminina',
    'during.prizes.pos1': '1<sup>a</sup> posición absoluta',
    'during.prizes.pos2': '2<sup>a</sup> posición absoluta',
    'during.prizes.pos3': '3<sup>a</sup> posición absoluta',
    'during.prizes.pos4': '4<sup>a</sup> posición absoluta',
    'during.prizes.pos5': '5<sup>a</sup> posición absoluta',
    'during.prizes.record': 'Rebaixar Tempo Record',
    'during.prizes.record.masc': 'Masculino: 31:27 minutos',
    'during.prizes.record.fem': 'Feminino: 34:38 minutos',
    'during.prizes.others': 'Outras categorías',
    'during.prizes.others.desc': 'Tamén obterán trofeo ós tres primeiros clasificados masculino e feminino de cada ' +
        'categoría',
    'during.prizes.special': 'Categorías especiais (trofeo)',
    'during.prizes.special.1': 'Club Federado con máis participantes chegados a meta',
    'during.prizes.special.2': 'Equipo Federado mellor clasificado na proba absoluta (mínino 5 participantes, e puntuarán os 3 primeiros)',
    'during.prizes.special.3': 'Equipo NON Federado mellor clasificado na proba absoluta (mínino 5 participantes, e puntuarán os 3 primeiros)',
    'during.prizes.special.4': 'O 1º corredor local masculino e femenino da clasificación da proba absoluta',
    'during.prizes.special.5': 'Premio especial “Cándido Calvo”, para o corredor e corredora de maior idade',
    'during.prizes.terms': 'Regras',
    'during.prizes.terms.desc': 'Para poder optar ós premios é importante coñecer que:',
    'during.prizes.terms.1': 'Os premios non serán acumulables.',
    'during.prizes.terms.2': 'Para optar a participación por Club Federado ou Grupo Non Federado, os atletas deberán ' +
        'realizar a súa inscrición individualmente e posteriormente deberán enviar por correo electrónico a dirección ' +
        '<a>i</a> un listado detallando os membros computables ó efecto.',
    'during.prizes.terms.3': 'En caso de empate entre clubs/grupos, premiarase o que menor suma total de tempos ' +
        'empregasen en rematar a proba, igualmente para os corredores de maior idade.',

    /** Results */
    'after.results.subtitle': 'Consulta aquí os resultados e clasificación da carreira nesta 6ª edición (2022).',

    /** Photos */
    'after.photos.subtitle': 'Aquí tes unha recopilación dos albumes de fotos feitas polos diferentes fotógrafos.',

    /** Dinner */
    'after.dinner.subtitle': 'Tras rematar a carreira hai que repoñer forzas. Aquí vos deixamos as nosas recomendacións.',

    /** Sleeping */
    'after.sleeping.subtitle': 'Por que non aproveitar xa que estás en Santiago, e pasar uns días de visita?',
    'after.sleeping.heading': 'Aloxamentos recomendados',
    'after.sleeping.subheading': 'Axudámosche a buscar un aloxamento para que non teñas que viaxar de noite, non ' +
        'te perdas o fin de festa que estamos a preparar. Quédate a vivir a maxia das noites compostelás.',
    'after.sleeping.p': 'Estes son os hoteis que colaboran connosco con prezos especiais, hai alternativas para ' +
        'todos os petos.',
    'after.sleeping.check': 'Mira no mapa a situación destes lugares (desplegando o menú)',

    /** Visiting */
    'after.visiting.subtitle': 'Santiago é unha Cidade Patrimonio da Humanidade. E, como tal, ten centenares de ' +
        'sitios turísticos para visitar. Non te perdas esta pequena guía para saber como atopalos.',
    'after.visiting.heading': 'Turismo por Santiago',
    'after.visiting.p1': 'Os nosos amigos da Oficina de Turismo de Santiago de Compostela dan algúns ' +
        'consellos para que poidades aproveitar a vosa estancia en Santiago. Ademáis, podedes atopar as propostas ' +
        'máis interesantes en función dos días dos que dispoñades.',
    'after.visiting.p2': 'E para ir abrindo boca, aquí tedes uns vídeos dalgunhas das cousas que facer na cidade.',

    /** Voluntarios */
    'volunteer.subtitle': 'Forma parte da familia SantYaGo10K',
    'volunteer.heading': 'Porque esto é máis ca unha carreira',
    'volunteer.p1': 'A preparación da SantYaGo10K é unha carreira de fondo (perdoade polo chiste). Un gran equipo ' +
        'está detrás de todo dende fai meses, para que todo salga perfecto e só haxa que desfrutar do día clave.',
    'volunteer.p2': 'Pero non somos suficientes. Queremos pedirvos a vosa colaboración, xa que a presencia de ' +
        'voluntarios é algo fundamental para un evento de tal magnitude. Tes algún familiar ou amigo corredor? Ven ' +
        'a apoialo sendo voluntario. Cantos máis sexamos mellor!',
    'volunteer.a1': 'Únete a nós o día 30 de Abril, e colabora coa organización da carreira como voluntario!',
    'volunteer.closed': 'Aínda non abrimos as inscripcións para voluntarios',
    'volunteer.p3': 'Só tes que introducir os teus datos no seguinte formulario. Terás además merenda e agasallos.',
    'volunteer.a2': 'Os <b>menores de idade</b> deberán remitirnos un documento de consentimento a asinar polos ' +
        'pais/nais/titores que poderán descargar dende <a>aquí</a>.',
    'volunteer.a3': 'Os <b>universitarios</b> poderán solicitar créditos da USC a través do Servicio de Participación ' +
        'e Integración Universitaria, sempre e cando cumpran cos <a>requisitos para o recoñecemento dos mesmos</a>.',

    /** Past Years */
    'info.pastyears.heading': 'Edicións anteriores',
    'info.pastyears.subheading': 'Descubre a orixe desta gran carreira',
    'info.pastyears.more': 'Ler máis',
    'info.pastyears.year': 'Ano',
    'info.pastyears.ed.1': '1ª Edición',
    'info.pastyears.ed.2': '2ª Edición',
    'info.pastyears.ed.3': '3ª Edición',
    'info.pastyears.ed.4': '4ª Edición',
    'info.pastyears.ed.5': '5ª Edición',
    'info.pastyears.ed.6': '6ª Edición',
    'info.pastyears.origin': 'Orixe',
    'info.pastyears.origins': '<p>' +
        'Esta carreira nace como un proxecto do Club de Atletismo Fontes do Sar e do Multiusos Fontes do Sar – ' +
        'Complexo Deportivo Santa Isabel, coa intención de traer á cidade de Santiago de Compostela unha Carreira ' +
        'Nocturna Homologada.</p>' +
        '<p>Traballamos coa idea de ofrecer un percorrido que permita desfrutar do encanto do casco histórico ' +
        'da cidade aos corredores populares, locales ou foráneos, e ao mesmo tempo ofrecer un trazado o ' +
        'máis chan posible e que permita desputar a carreira a aqueles que fan do atletismo un modo de vida.</p>' +
        '<p>Afortunadamente contamos coa promoción do Concello de Santiago a través de varias concellerías, entre ' +
        'elas a Concellería de Servicios Sociales por medio do Departamento Municipal de Deportes do Concello ' +
        'de Santiago ou a Concellería de Economía, no tocante ao Turismo en Santiago</p>' +
        '<p>A nosa intención é iniciar e consolidar un evento anual; buscamos convertilo nun referente ' +
        'deportivo e turístico ao igual que aoutras competcións de alto nivel desenroladas noutras cidades ' +
        'de Galicia (Coruña, Vigo, Ourense, etc), pero ante todo e sobre todo esperamos que… DESFRUTEDES CORRENDO!</p>',

    /** Regulation */
    'info.regulation.subtitle': 'Regulamento da Carreira Nocturna SantYaGo10K',
    'info.regulation.galician': 'Galego',

    /** Terms */
    'info.terms.subtitle': 'Términos e Condicións de uso da páxina web',

    /** Press */
    'info.press.subtitle': 'Aquí iremos enlazando a información recollida en medios sobre a carreira.',
    'info.press.read': 'Ler noticia',

    /** Contact */
    'contact.heading': 'Tes dúbidas?',
    'contact.subheading': 'Envíanos un correo e resolverémola o antes posible.',
    'contact.subject': 'Asunto da mensaxe',
    'contact.message': 'Mensaxe...',
    'contact.send': 'Enviar',
};

export type LanguageStrings = typeof baseStrings;
export const ca = baseStrings;