import React from "react";
import {Helmet} from 'react-helmet';

import {
    Card,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";
import {Route} from "../../modules/i18n";
import {AppRoute} from "../../const";
import {Link} from "react-router-dom";
import Protect from "../../modules/password/Controller";


export const Admin: React.FC = () => {
    React.useEffect(() => {
        Protect();
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>Admin | SantYaGo10K 2022</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped pb-250">
                        <div className="shape shape-style-1 bg-gradient-gray">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            Administración
                                        </h1>
                                        <p className="lead text-white">
                                            Acceso restrinxido á organización
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section pt-lg-0 mt--200">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg="12">
                                    <Row className="row-grid">
                                        <Col lg="4">
                                            <Card className="shadow border-0">
                                                <CardBody className="py-5">
                                                    <h6 className="text-warning text-uppercase">
                                                        Calendario
                                                    </h6>
                                                    <Link
                                                        className="mt-4"
                                                        color="warning"
                                                        to={Route(AppRoute.Calendar)}
                                                    >
                                                        Ver Calendario
                                                    </Link>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="4">
                                            <Card className="shadow border-0">
                                                <CardBody className="py-5">
                                                    <h6 className="text-warning text-uppercase">
                                                        info@santyago10k.run
                                                    </h6>
                                                    <a
                                                        className="mt-4"
                                                        color="warning"
                                                        href="https://groups.google.com/a/santyago10k.run/forum/#!forum/info"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Ver Correo
                                                    </a>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Admin;
