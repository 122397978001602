import React from "react";
import {Helmet} from 'react-helmet';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Badge,
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {Moving, VerticalSponsors} from "../../modules/layout";


export const Parking: React.FC = () => {
    const {formatMessage} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "before.parking.title"})} | SantYaGo10K 2022</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 bg-gradient-blue">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "before.parking.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "before.parking.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/parking.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <Badge color="warning" pill className="mr-1">
                                        {formatMessage({id: "wip.confirmation"})}
                                    </Badge>
                                    <h1>{formatMessage({id: "before.parking.heading"})}</h1>
                                    <Row>
                                        <Col lg="6" className="order-lg-2">
                                            <p>{formatMessage({id: "before.parking.subheading"})}</p>
                                        </Col>
                                        <Col lg="6" className="order-lg-1">
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../assets/img/undraw/parking.png")}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h3>Parking Catedral</h3>
                                            <Row>
                                                <Col lg="8">
                                                    <FormattedMessage
                                                        tagName="p"
                                                        id="before.parking.catedral.p1"
                                                        values={{
                                                            b: (chunks: React.ReactNode) => (
                                                                <span style={{fontWeight: "bold"}}>{chunks}</span>
                                                            ),
                                                        }}
                                                    />
                                                    <p>{formatMessage({id: "before.parking.catedral.p2"})}</p>
                                                </Col>
                                                <Col lg="4">
                                                    <img
                                                        alt="..."
                                                        className="img-fluid floating-lg"
                                                        src={require("../../assets/img/race/sponsors/other/parking.png")}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <ul className="list-unstyled mt-5">
                                                <li className="py-2">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <Badge className="badge-circle mr-3" color="warning">
                                                                <i className="fa fa-check"/>
                                                            </Badge>
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0">{formatMessage({id: "before.parking.catedral.f1"})}</h6>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <Badge className="badge-circle mr-3" color="warning">
                                                                <i className="fa fa-check"/>
                                                            </Badge>
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0">{formatMessage({id: "before.parking.catedral.f2"})}</h6>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <Badge className="badge-circle mr-3" color="warning">
                                                                <i className="fa fa-check"/>
                                                            </Badge>
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0">{formatMessage({id: "before.parking.catedral.f3"})}</h6>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <Badge className="badge-circle mr-3" color="warning">
                                                                <i className="fa fa-check"/>
                                                            </Badge>
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0">{formatMessage({id: "before.parking.catedral.f4"})}</h6>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <Badge className="badge-circle mr-3" color="warning">
                                                                <i className="fa fa-check"/>
                                                            </Badge>
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0">{formatMessage({id: "before.parking.catedral.f5"})}</h6>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <Badge className="badge-circle mr-3" color="warning">
                                                                <i className="fa fa-check"/>
                                                            </Badge>
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0">{formatMessage({id: "before.parking.catedral.f6"})}</h6>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <Badge className="badge-circle mr-3" color="warning">
                                                                <i className="fa fa-check"/>
                                                            </Badge>
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0">{formatMessage({id: "before.parking.catedral.f7"})}</h6>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="6">
                                            <Card className="shadow shadow-sm mt-5">
                                                <CardBody>
                                                    <div className="d-flex px-3">
                                                        <div className="pl-4">
                                                            <h5 className="title text-warning">
                                                                {formatMessage({id: "before.parking.catedral.contact"})}
                                                            </h5>
                                                            <p>C/ Galeras, 9-17<br/>15705 - Santiago de Compostela</p>
                                                            <p><span className="fa fa-phone"/> (+34) 981 57 65 62</p>
                                                            <p><span className="fa fa-globe"/> <a
                                                                href="http://parkingcatedral.com/" target="_blank"
                                                                rel="noopener noreferrer">parkingcatedral.com</a></p>
                                                            <p><span className="fa fa-map-marker"/> <a
                                                                href="https://goo.gl/maps/hBr7mq43yQi2oeRQ7"
                                                                target="_blank" rel="noopener noreferrer">Google
                                                                Maps</a></p>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="8">
                                            <img
                                                alt="..."
                                                className="img-fluid shadow-lg"
                                                src={require('../../assets/img/others/parking.png')}
                                            />
                                        </Col>
                                        <Col lg="4">
                                            <p><small>{formatMessage({id: "before.parking.catedral.t1"})}</small></p>
                                            <p><small>{formatMessage({id: "before.parking.catedral.t2"})}</small></p>
                                        </Col>
                                    </Row>
                                    <Moving/>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Parking;
